import { SOCIAL_MEDIA } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

export type GetSocialMediaResponse = BaseResponse<{
  facebook: {
    url: string
    visible: boolean
  }
  instagram: {
    url: string
    visible: boolean
  }
  tiktok: {
    url: string
    visible: boolean
  }
  twitter: {
    url: string
    visible: boolean
  }
  version: string
}>

export const getSocialMedia = async () => {
  const response = await api<GetSocialMediaResponse>({
    url: SOCIAL_MEDIA,
    method: 'GET',
  })

  return response.data
}

export const getSocialMediaKey = 'GET_SOCIAL_MEDIA'
