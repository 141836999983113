import { FAQ_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type FaqItem = {
  xid: string
  name: string
  description: string
  cover: FileData
} & TimestampAndVersion

export type GetDetailFaqRequestParams = {
  faqTopicsXid: string
  faqXid: string
}

export type GetDetailFaqRequest = {
  params: GetDetailFaqRequestParams
}

export type GetDetailFaqResponse = BaseResponse<
  {
    xid: string
    question: string
    answer: string
    statusId: number
  } & TimestampAndVersion
>

export const getDetailFaq = async (request: GetDetailFaqRequest) => {
  const response = await api<GetDetailFaqResponse, GetDetailFaqRequestParams>({
    ...request,
    url: FAQ_DETAIL + '/detail',
    method: 'GET',
  })

  return response.data
}

export const getDetailFaqKey = 'GET_DETAIL_FAQ'
