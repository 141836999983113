import { FAQ_TOPICS_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type FaqTopicItem = {
  xid: string
  name: string
  description: string
  cover: FileData
} & TimestampAndVersion

export type GetDetailFaqTopicRequestParams = {
  faqTopicsXid: string
}

export type GetDetailFaqTopicRequest = {
  params: GetDetailFaqTopicRequestParams
}

export type GetDetailFaqTopicResponse = BaseResponse<
  {
    xid: string
    name: string
    faqCount: string
    statusId: number
  } & TimestampAndVersion
>

export const getDetailFaqTopic = async (request: GetDetailFaqTopicRequest) => {
  const response = await api<GetDetailFaqTopicResponse, GetDetailFaqTopicRequestParams>({
    ...request,
    url: FAQ_TOPICS_DETAIL + '/detail',
    method: 'GET',
  })

  return response.data
}

export const getDetailFaqTopicKey = 'GET_DETAIL_FAQ_TOPIC'
