import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getSocialMedia as queryFn, getSocialMediaKey } from '@/services'

export const useGetSocialMedia = (enabled = true) => {
  const {
    refetch: getSocialMedia,
    data: getSocialMediaData,
    error: getSocialMediaError,
    isFetching: getSocialMediaIsFetching,
    isPending: getSocialMediaIsPending,
    isSuccess: getSocialMediaIsSuccess,
    isError: getSocialMediaIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(),
    queryKey: [getSocialMediaKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getSocialMedia,
    getSocialMediaData,
    getSocialMediaError,
    getSocialMediaIsFetching,
    getSocialMediaIsPending,
    getSocialMediaIsSuccess,
    getSocialMediaIsError,
  }
}

export const useSuspenseGetSocialMedia = () => {
  const { data: getSocialMediaData } = useSuspenseQuery(getSocialMediaOptions())

  return { getSocialMediaData }
}

export const getSocialMediaOptions = () =>
  queryOptions({
    queryFn: () => queryFn(),
    queryKey: [getSocialMediaKey],
  })
