import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailFaq as queryFn, getDetailFaqKey, GetDetailFaqRequest } from '@/services'

export const useGetDetailFaq = (request: GetDetailFaqRequest, enabled = true) => {
  const {
    refetch: getDetailFaq,
    data: getDetailFaqData,
    error: getDetailFaqError,
    isFetching: getDetailFaqIsFetching,
    isPending: getDetailFaqIsPending,
    isSuccess: getDetailFaqIsSuccess,
    isError: getDetailFaqIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailFaqKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailFaq,
    getDetailFaqData,
    getDetailFaqError,
    getDetailFaqIsFetching,
    getDetailFaqIsPending,
    getDetailFaqIsSuccess,
    getDetailFaqIsError,
  }
}

export const useSuspenseGetDetailFaq = (request: GetDetailFaqRequest) => {
  const { data: getDetailFaqData } = useSuspenseQuery(getDetailFaqOptions(request))

  return { getDetailFaqData }
}

export const getDetailFaqOptions = (request: GetDetailFaqRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailFaqKey, request],
  })
