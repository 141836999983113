import { SidebarMenuData } from '@nbsdev/naini-react'

import { Abilities } from './enum'

export const sidebarMenu: SidebarMenuData[] = [
  {
    id: 'dashboard',
    type: 'link',
    icon: 'ri-dashboard-fill',
    path: '/dashboard',
    label: 'sidebarMenu:dashboard',
    access: [Abilities.ReadDashboard],
    active: ['/main/dashboard'],
  },
  {
    id: 'banner',
    type: 'link',
    icon: 'ri-image-2-fill',
    path: '/banner',
    label: 'sidebarMenu:bannerManagement',
    access: [Abilities.ReadBanner],
    active: ['/main/banner', '/main/banner/create', '/main/banner/$xid/edit'],
  },
  {
    id: 'flipbook',
    type: 'link',
    icon: 'ri-dashboard-line',
    path: '/flipbook',
    label: 'sidebarMenu:flipbookManagement',
    access: [Abilities.ReadBanner],
    active: ['/main/flipbook', '/main/flipbook/create', '/main/flipbook/$xid/edit'],
  },

  // Mobile Content
  {
    id: 'mobile-content',
    type: 'divider',
    label: 'sidebarMenu:mobileContent.title',
    access: [Abilities.ReadBanner, Abilities.ReadNotificationBroadcast, Abilities.ReadSplashScreen],
  },
  {
    id: 'notification-broadcast',
    type: 'link',
    icon: 'ri-broadcast-fill',
    path: '/notification-broadcast',
    label: 'sidebarMenu:mobileContent.notificationBroadcast',
    access: [Abilities.ReadNotificationBroadcast],
    active: [
      '/main/notification-broadcast',
      '/main/notification-broadcast/create',
      '/main/notification-broadcast/$xid/edit',
      '/main/notification-broadcast/$xid/detail',
    ],
  },
  {
    id: 'dynamic-splash-screen',
    type: 'link',
    icon: 'ri-flashlight-fill',
    path: '/dynamic-splash-screen',
    label: 'sidebarMenu:tvContent.dynamicSplashScreen',
    access: [Abilities.ReadSplashScreen],
    active: [
      '/main/dynamic-splash-screen',
      '/main/dynamic-splash-screen/create',
      '/main/dynamic-splash-screen/$xid/edit',
    ],
  },

  // TV Content
  {
    id: 'tv-content',
    type: 'divider',
    label: 'sidebarMenu:tvContent.title',
    access: [Abilities.ReadSlideShow, Abilities.ReadStaticSplashScreen],
  },
  {
    id: 'slide-show',
    type: 'link',
    icon: 'ri-video-fill',
    path: '/slide-show',
    label: 'sidebarMenu:tvContent.slideShow',
    access: [Abilities.ReadSlideShow],
    active: ['/main/slide-show', '/main/slide-show/$xid/edit'],
  },
  {
    id: 'highlights',
    type: 'link',
    icon: 'ri-star-fill',
    path: '/highlights',
    label: 'sidebarMenu:tvContent.highlights',
    access: [Abilities.ReadProgramHighlight],
    active: ['/main/highlights'],
  },
  // {
  //   id: 'program-highlights',
  //   type: 'link',
  //   icon: 'ri-star-fill',
  //   path: '/',
  //   label: 'sidebarMenu:tvContent.programHighlights',
  //   active: ['program-highlights'],
  // },
  {
    id: 'static-splash-screen',
    type: 'link',
    icon: 'ri-flashlight-fill',
    path: '/static-splash-screen',
    label: 'sidebarMenu:tvContent.staticSplashScreen',
    access: [Abilities.ReadStaticSplashScreen],
    active: ['/main/static-splash-screen'],
  },

  // Mobile & TV Content
  {
    id: 'mobile-tv-content',
    type: 'divider',
    label: 'sidebarMenu:mobileTvContent.title',
    access: [
      Abilities.ReadDynamicContent,
      Abilities.ReadProgramLibrary,
      Abilities.ReadProgramStatistic,
      Abilities.ReadLiveChannel,
      Abilities.ReadProgramCoverage,
    ],
  },
  {
    id: 'dynamic-content',
    type: 'link',
    icon: 'ri-stack-fill',
    path: '/dynamic-content',
    label: 'sidebarMenu:mobileTvContent.dynamicContent',
    access: [Abilities.ReadDynamicContent],
    active: ['/main/dynamic-content', '/main/dynamic-content/create', '/main/dynamic-content/$xid/edit'],
  },
  {
    id: 'program-library',
    type: 'menu',
    icon: 'ri-film-fill',
    path: '/',
    label: 'sidebarMenu:mobileTvContent.programLibrary.title',
    access: [Abilities.ReadProgramNeedReview, Abilities.ReadSubmissionProgram, Abilities.ReadProgramLibrary],
    active: [
      '/main/program-need-review',
      '/main/program-need-review/$xid/general-data',
      '/main/program-need-review/$xid/episodes',
      '/main/program-need-review/$xid/info',
      '/main/program-need-review/$xid/cover',
      '/main/program-need-review/$xid/publication',
      '/main/program-submission/$xid/detail/general',
      '/main/program-submission/$xid/detail/episodes',
      '/main/program-submission/$xid/detail/info',
      '/main/program-submission/$xid/detail/cover',
      '/main/program-submission/$xid/detail/publication',
      '/main/program-active',
      '/main/program-active/$xid/general-data',
      '/main/program-active/$xid/episodes',
      '/main/program-active/$xid/cover',
      '/main/program-active/$xid/info',
      '/main/program-submission',
      '/main/program-submission/create/type',
      '/main/program-submission/create/general',
      '/main/program-submission/create/$xid/draft/general',
      '/main/program-submission/create/$xid/draft/video',
      '/main/program-submission/create/$xid/draft/other-information',
      '/main/program-submission/create/$xid/draft/cover',
      '/main/program-submission/create/$xid/draft/publication',
      '/main/program-submission/create/$xid/draft/video/edit/$episodeXid/episode',
      '/main/program-active/$xid/edit',
      '/main/program-active/$xid/edit/general-data',
      '/main/program-active/$xid/edit/episodes',
      '/main/program-active/$xid/edit/info',
      '/main/program-active/$xid/edit/cover',
      '/main/program-active/$xid/edit/publication',
    ],
    items: [
      {
        id: 'program-need-review',
        type: 'link',
        path: '/program-need-review',
        label: 'sidebarMenu:mobileTvContent.programLibrary.needReview',
        access: [Abilities.ReadProgramNeedReview],
        active: [
          '/main/program-need-review',
          '/main/program-need-review/$xid/general-data',
          '/main/program-need-review/$xid/episodes',
          '/main/program-need-review/$xid/info',
          '/main/program-need-review/$xid/cover',
          '/main/program-need-review/$xid/publication',
        ],
      },
      {
        id: 'program-submission',
        type: 'link',
        path: '/program-submission',
        label: 'sidebarMenu:mobileTvContent.programLibrary.submission',
        access: [Abilities.ReadSubmissionProgram],
        active: [
          '/main/program-submission',
          '/main/program-submission/create/type',
          '/main/program-submission/create/general',
          '/main/program-submission/create/$xid/draft/general',
          '/main/program-submission/create/$xid/draft/video',
          '/main/program-submission/create/$xid/draft/other-information',
          '/main/program-submission/create/$xid/draft/cover',
          '/main/program-submission/create/$xid/draft/publication',
          '/main/program-submission/create/$xid/draft/video/edit/$episodeXid/episode',
          '/main/program-submission/$xid/detail/general',
          '/main/program-submission/$xid/detail/episodes',
          '/main/program-submission/$xid/detail/info',
          '/main/program-submission/$xid/detail/cover',
          '/main/program-submission/$xid/detail/publication',
        ],
      },
      {
        id: 'program-active',
        type: 'link',
        path: '/program-active',
        label: 'sidebarMenu:mobileTvContent.programLibrary.active',
        access: [Abilities.ReadProgramLibrary],
        active: [
          '/main/program-active',
          '/main/program-active/$xid/general-data',
          '/main/program-active/$xid/episodes',
          '/main/program-active/$xid/info',
          '/main/program-active/$xid/cover',
          '/main/program-active/$xid/edit',
          '/main/program-active/$xid/edit/general-data',
          '/main/program-active/$xid/edit/episodes',
          '/main/program-active/$xid/edit/info',
          '/main/program-active/$xid/edit/cover',
          '/main/program-active/$xid/edit/publication',
        ],
      },
    ],
  },
  {
    id: 'program-statistic',
    type: 'link',
    icon: 'ri-bar-chart-fill',
    path: '/program-statistic',
    label: 'sidebarMenu:mobileTvContent.programStatistic',
    access: [Abilities.ReadProgramStatistic],
    active: ['/main/program-statistic'],
  },
  // {
  //   id: 'program-assessment',
  //   type: 'link',
  //   icon: 'ri-thumb-up-fill',
  //   path: '/',
  //   label: 'sidebarMenu:mobileTvContent.programAssessment',
  //   active: ['program-assessment'],
  // },
  {
    id: 'live-channel',
    type: 'link',
    icon: 'ri-live-fill',
    path: '/live-channel',
    label: 'sidebarMenu:mobileTvContent.liveChannel',
    access: [Abilities.ReadLiveChannel],
    active: ['/main/live-channel', '/main/live-channel/$xid/edit/general', '/main/live-channel/$xid/edit/schedule'],
  },
  // {
  //   id: 'program-coverage',
  //   type: 'link',
  //   icon: 'ri-movie-fill',
  //   path: '/program-coverage',
  //   label: 'sidebarMenu:mobileTvContent.programCoverage',
  //   access: [Abilities.ReadProgramCoverage],
  //   active: ['/main/program-coverage', '/main/program-coverage/create', '/main/program-coverage/$xid/edit'],
  // },
  // {
  //   id: 'splash-screen',
  //   type: 'link',
  //   icon: 'ri-flashlight-fill',
  //   path: '/',
  //   label: 'sidebarMenu:mobileTvContent.splashScreen',
  //   active: ['splash-screen'],
  // },

  // Data & Information
  {
    id: 'data-information',
    type: 'divider',
    label: 'sidebarMenu:dataInformation.title',
  },
  {
    id: 'master-data',
    type: 'menu',
    icon: 'ri-database-2-fill',
    label: 'sidebarMenu:dataInformation.masterData.title',
    access: [
      Abilities.ReadMasterDataTopLevelCategory,
      Abilities.ReadMasterDataCast,
      Abilities.ReadMasterDataFigure,
      Abilities.ReadMasterDataDirector,
      Abilities.ReadMasterDataAgeRating,
      Abilities.ReadMasterDataTagging,
      Abilities.ReadMasterDataSubject,
      Abilities.ReadMasterDataGuestSpeaker,
    ],
    active: [
      '/main/cast',
      '/main/cast/create',
      '/main/cast/$xid/edit',
      '/main/figure',
      '/main/figure/create',
      '/main/figure/$xid/edit',
      '/main/director',
      '/main/director/create',
      '/main/director/$xid/edit',
      '/main/age-rating',
      '/main/age-rating/create',
      '/main/age-rating/$xid/edit',
      '/main/category',
      '/main/category/create',
      '/main/category/$xid/edit',
      '/main/category/$xid/edit/$subXid/edit-sub-category',
      '/main/category/$xid/create-sub-category',
      '/main/tagging',
      '/main/tagging/$xid/edit',
      '/main/subject',
      '/main/subject/create',
      '/main/subject/$xid/edit',
      '/main/speaker',
      '/main/speaker/create',
      '/main/speaker/$xid/edit',
    ],
    items: [
      {
        id: 'category',
        type: 'link',
        path: '/category',
        label: 'sidebarMenu:dataInformation.masterData.category',
        access: [Abilities.ReadMasterDataTopLevelCategory],
        active: [
          '/main/category',
          '/main/category/create',
          '/main/category/$xid/edit',
          '/main/category/$xid/edit-sub-category',
          '/main/category/$xid/create-sub-category',
          '/main/category/$xid/edit/$subXid/edit-sub-category',
        ],
      },
      {
        id: 'cast',
        type: 'link',
        path: '/cast',
        label: 'sidebarMenu:dataInformation.masterData.cast',
        access: [Abilities.ReadMasterDataCast],
        active: ['/main/cast', '/main/cast/create', '/main/cast/$xid/edit'],
      },
      {
        id: 'figure',
        type: 'link',
        path: '/figure',
        label: 'sidebarMenu:dataInformation.masterData.figure',
        access: [Abilities.ReadMasterDataFigure],
        active: ['/main/figure', '/main/figure/create', '/main/figure/$xid/edit'],
      },
      {
        id: 'director',
        type: 'link',
        path: '/director',
        label: 'sidebarMenu:dataInformation.masterData.director',
        access: [Abilities.ReadMasterDataDirector],
        active: ['/main/director', '/main/director/create', '/main/director/$xid/edit'],
      },
      {
        id: 'age-rating',
        type: 'link',
        path: '/age-rating',
        label: 'sidebarMenu:dataInformation.masterData.ageRating',
        access: [Abilities.ReadMasterDataAgeRating],
        active: ['/main/age-rating', '/main/age-rating/create', '/main/age-rating/$xid/edit'],
      },
      {
        id: 'tagging',
        type: 'link',
        path: '/tagging',
        label: 'sidebarMenu:dataInformation.masterData.tag',
        access: [Abilities.ReadMasterDataTagging],
        active: ['/main/tagging', '/main/tagging/$xid/edit'],
      },
      {
        id: 'subject',
        type: 'link',
        path: '/subject',
        label: 'sidebarMenu:dataInformation.masterData.subject',
        access: [Abilities.ReadMasterDataTagging],
        active: ['/main/subject', '/main/subject/create', '/main/subject/$xid/edit'],
      },
      {
        id: 'speaker',
        type: 'link',
        path: '/speaker',
        label: 'sidebarMenu:dataInformation.masterData.speaker',
        access: [Abilities.ReadMasterDataTagging],
        active: ['/main/speaker', '/main/speaker/create', '/main/speaker/$xid/edit'],
      },
    ],
  },
  {
    id: 'other-information',
    type: 'menu',
    icon: 'ri-information-fill',
    label: 'sidebarMenu:dataInformation.otherInformation.title',
    active: ['/main/term-condition', '/main/privacy-policy', '/main/feedback-url', '/main/about-us'],
    access: [
      Abilities.ReadOtherInformationAboutUs,
      Abilities.ReadOtherInformationContact,
      Abilities.ReadOtherInformationTermCondition,
      Abilities.ReadOtherInformationPrivacyPolicy,
      Abilities.ReadOtherInformationFeedbackUrl,
    ],
    items: [
      {
        id: 'about',
        type: 'link',
        path: '/about-us',
        label: 'sidebarMenu:dataInformation.otherInformation.about',
        access: [Abilities.ReadOtherInformationAboutUs],
        active: ['/main/about-us'],
      },
      {
        id: 'social-media-store',
        type: 'link',
        path: '/social-media',
        label: 'sidebarMenu:dataInformation.otherInformation.socialMediaStore',
        access: [Abilities.ReadOtherInformationContact],
        active: ['/main/social-media', '/main/store'],
      },
      {
        id: 'term-condition',
        type: 'link',
        path: '/term-condition',
        label: 'sidebarMenu:dataInformation.otherInformation.termCondition',
        access: [Abilities.ReadOtherInformationTermCondition],
        active: ['/main/term-condition'],
      },
      {
        id: 'privacy-policy',
        type: 'link',
        path: '/privacy-policy',
        label: 'sidebarMenu:dataInformation.otherInformation.privacyPolicy',
        access: [Abilities.ReadOtherInformationPrivacyPolicy],
        active: ['/main/privacy-policy'],
      },
      {
        id: 'feedback-url',
        type: 'link',
        path: '/feedback-url',
        label: 'sidebarMenu:dataInformation.otherInformation.feedbackUrl',
        access: [Abilities.ReadOtherInformationFeedbackUrl],
        active: ['/main/feedback-url'],
      },
    ],
  },
  // {
  //   id: 'feedback',
  //   type: 'link',
  //   icon: 'ri-chat-heart-fill',
  //   path: '/',
  //   label: 'sidebarMenu:dataInformation.feedback',
  //   active: ['feedback'],
  // },
  {
    id: 'faq',
    type: 'link',
    icon: 'ri-questionnaire-fill',
    path: '/faq-topic',
    label: 'sidebarMenu:dataInformation.faq',
    access: [
      Abilities.ReadOtherInformationFaq,
      Abilities.CreateOtherInformationFaq,
      Abilities.UpdateOtherInformationFaq,
      Abilities.DeleteOtherInformationFaq,
    ],
    active: [
      'main/faq-topic',
      'main/faq-topic',
      'main/faq-topic/create',
      'main/faq-topic/$faqTopicXid/edit',
      'main/faq-topic/$faqTopicXid/create-faq',
      'main/faq-topic/$faqTopicXid/edit/$faqXid/edit-faq',
    ],
  },

  // User & Role
  {
    id: 'user-role',
    type: 'divider',
    label: 'sidebarMenu:userRole.title',
    access: [Abilities.ReadUserApplication, Abilities.ReadAdminCMS, Abilities.ReadUserManagement],
  },
  {
    id: 'users-app',
    type: 'link',
    icon: 'ri-group-fill',
    path: '/users-app',
    label: 'sidebarMenu:userRole.appUser',
    access: [Abilities.ReadUserApplication],
    active: ['/main/users-app'],
  },
  {
    id: 'users-cms',
    type: 'link',
    icon: 'ri-user-settings-fill',
    path: '/users-cms',
    label: 'sidebarMenu:userRole.cmsUser',
    access: [Abilities.ReadAdminCMS],
    active: ['/main/users-cms', '/main/users-cms/create', '/main/users-cms/$xid/edit'],
  },
  {
    id: 'role-management',
    type: 'link',
    icon: 'ri-settings-4-fill',
    path: '/role-management',
    label: 'sidebarMenu:userRole.roleManagement',
    access: [Abilities.ReadUserManagement],
    active: ['/main/role-management', '/main/role-management/create', '/main/role-management/$xid/edit'],
  },
]
