import { ABOUT_US } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailAboutUsResponse = BaseResponse<{
  title: string
  description: string
  version: string
}>

export const getDetailAboutUs = async () => {
  const response = await api<GetDetailAboutUsResponse>({
    url: ABOUT_US,
    method: 'GET',
  })

  return response.data
}

export const getDetailAboutUsKey = 'GET_DETAIL_ABOUT_US'
