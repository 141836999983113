import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailAboutUs as queryFn, getDetailAboutUsKey } from '@/services'

export const useGetDetailAboutUs = () => {
  const {
    refetch: getDetailAboutUs,
    data: getDetailAboutUsData,
    error: getDetailAboutUsError,
    isFetching: getDetailAboutUsIsFetching,
    isPending: getDetailAboutUsIsPending,
    isSuccess: getDetailAboutUsIsSuccess,
    isError: getDetailAboutUsIsError,
  } = useQuery({
    queryFn,
    queryKey: [getDetailAboutUsKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailAboutUs,
    getDetailAboutUsData,
    getDetailAboutUsError,
    getDetailAboutUsIsFetching,
    getDetailAboutUsIsPending,
    getDetailAboutUsIsSuccess,
    getDetailAboutUsIsError,
  }
}

export const useSuspenseGetDetailAboutUs = () => {
  const { data: getDetailAboutUsData } = useSuspenseQuery(getDetailAboutUsOptions())

  return { getDetailAboutUsData }
}

export const getDetailAboutUsOptions = () =>
  queryOptions({
    queryFn,
    queryKey: [getDetailAboutUsKey],
  })
