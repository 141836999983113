import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailFaqTopic as queryFn, getDetailFaqTopicKey, GetDetailFaqTopicRequest } from '@/services'

export const useGetDetailFaqTopic = (request: GetDetailFaqTopicRequest, enabled = true) => {
  const {
    refetch: getDetailFaqTopic,
    data: getDetailFaqTopicData,
    error: getDetailFaqTopicError,
    isFetching: getDetailFaqTopicIsFetching,
    isPending: getDetailFaqTopicIsPending,
    isSuccess: getDetailFaqTopicIsSuccess,
    isError: getDetailFaqTopicIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailFaqTopicKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailFaqTopic,
    getDetailFaqTopicData,
    getDetailFaqTopicError,
    getDetailFaqTopicIsFetching,
    getDetailFaqTopicIsPending,
    getDetailFaqTopicIsSuccess,
    getDetailFaqTopicIsError,
  }
}

export const useSuspenseGetDetailFaqTopic = (request: GetDetailFaqTopicRequest) => {
  const { data: getDetailFaqTopicData } = useSuspenseQuery(getDetailFaqTopicOptions(request))

  return { getDetailFaqTopicData }
}

export const getDetailFaqTopicOptions = (request: GetDetailFaqTopicRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailFaqTopicKey, request],
  })
