import { STORE } from '@/configs/endpoint'
import { ControlStatus } from '@/configs/enum'
import { BaseResponse, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetStoreResponse = BaseResponse<
  {
    playStore: {
      url: string
      visible: boolean
    }
    appleStore: {
      url: string
      visible: boolean
    }
    description: {
      text: string
      visible: boolean
    }
    statusId: ControlStatus
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getStore = async () => {
  const response = await api<GetStoreResponse>({
    url: STORE,
    method: 'GET',
  })

  return response.data
}

export const getStoreKey = 'GET_STORE'
