import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailFlipbook as queryFn, getDetailFlipbookKey, GetDetailFlipbookRequest } from '@/services'

export const useGetDetailFlipbook = (request: GetDetailFlipbookRequest, enabled = true) => {
  const {
    refetch: getDetailFlipbook,
    data: getDetailFlipbookData,
    error: getDetailFlipbookError,
    isFetching: getDetailFlipbookIsFetching,
    isPending: getDetailFlipbookIsPending,
    isSuccess: getDetailFlipbookIsSuccess,
    isError: getDetailFlipbookIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailFlipbookKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailFlipbook,
    getDetailFlipbookData,
    getDetailFlipbookError,
    getDetailFlipbookIsFetching,
    getDetailFlipbookIsPending,
    getDetailFlipbookIsSuccess,
    getDetailFlipbookIsError,
  }
}

export const useSuspenseGetDetailFlipbook = (request: GetDetailFlipbookRequest) => {
  const { data: getDetailFlipbookData } = useSuspenseQuery(getDetailFlipbookOptions(request))

  return { getDetailFlipbookData }
}

export const getDetailFlipbookOptions = (request: GetDetailFlipbookRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailFlipbookKey, request],
  })
