import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getStore as queryFn, getStoreKey } from '@/services'

export const useGetStore = (enabled = true) => {
  const {
    refetch: getStore,
    data: getStoreData,
    error: getStoreError,
    isFetching: getStoreIsFetching,
    isPending: getStoreIsPending,
    isSuccess: getStoreIsSuccess,
    isError: getStoreIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(),
    queryKey: [getStoreKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getStore,
    getStoreData,
    getStoreError,
    getStoreIsFetching,
    getStoreIsPending,
    getStoreIsSuccess,
    getStoreIsError,
  }
}

export const useSuspenseGetStore = () => {
  const { data: getStoreData } = useSuspenseQuery(getStoreOptions())

  return { getStoreData }
}

export const getStoreOptions = () =>
  queryOptions({
    queryFn: () => queryFn(),
    queryKey: [getStoreKey],
  })
