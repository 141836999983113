import { FLIPBOOK_DETAIL } from '@/configs/endpoint'
import { ControlStatus, ConvertStatus } from '@/configs/enum'
import { BaseResponse, FileData, ModifiedBy } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailFlipbookRequestParams = {
  xid: string
}

export type GetDetailFlipbookRequest = {
  params: GetDetailFlipbookRequestParams
}

export type GetDetailFlipbookResponse = BaseResponse<{
  xid: string
  name: string
  releaseYear: string
  version: string
  createdAt: string
  updatedAt: string
  modifiedBy: ModifiedBy
  statusId: ControlStatus
  cover: FileData
  rawFile: FileData
  convertStatusId: ConvertStatus
  images: string[]
}>

export const getDetailFlipbook = async (request: GetDetailFlipbookRequest) => {
  const response = await api<GetDetailFlipbookResponse, GetDetailFlipbookRequestParams>({
    ...request,
    url: FLIPBOOK_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailFlipbookKey = 'GET_DETAIL_FLIPBOOK'
